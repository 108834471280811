<template>
  <el-drawer
    :size="canEdit ? '55%' : '40%'"
    :before-close="handleClose"
    append-to-body
    :visible.sync="show"
  >
    <div class="media-detail padding-20" v-loading="loading">
      <div class="left" v-if="show && canEdit">
        <!-- <template v-if="itemList.length > 0"> -->
        <wx-preview
          ref="wxPreview"
          :canEdit="canEdit"
          :channelInfo="channelInfo"
          :itemList="itemList"
          @chooseItem="chooseItem"
        ></wx-preview>
        <!-- </template> -->
      </div>
      <div class="right" :style="getStyle()" v-if="curInfo">
        <div class="main-container padding-20">
          <h3>图文详情</h3>
          <el-form
            :model="curInfo"
            ref="ruleForm"
            :rules="rules"
            label-width="10vw"
          >
            <el-form-item label="公众号">
              <span>{{ channelInfo.accountChannel.channelName }}</span>
            </el-form-item>
            <el-form-item label="素材ID" prop="tagSource">
              <div
                style="
                  height: 32px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <el-button
                  v-if="canEdit"
                  type="primary"
                  class="button-small margin-right-ten"
                  @click="chooseResource"
                  >选择素材</el-button
                >
                <!-- <template v-if="curInfo.resource">
                  <el-popover placement="top" trigger="hover">
                    <div
                      style="
                        display: inline-block;
                        font-size: 14px;
                        width: 350px;
                      "
                      class="line-overflow"
                      slot="reference"
                    >
                      {{
                        `书籍：《${curInfo.tagSource.bookName}》  截止章节：${
                          curInfo.tagSource.endIdx
                        }
                    备注：${
                      curInfo.tagSource.remark ? curInfo.tagSource.remark : "暂无"
                    }`
                      }}
                    </div>
                    <div>
                      {{
                        `书籍：《${curInfo.tagSource.bookName}》  截止章节：${
                          curInfo.tagSource.endIdx
                        }
                    备注：${
                      curInfo.tagSource.remark ? curInfo.tagSource.remark : "暂无"
                    }`
                      }}
                    </div>
                  </el-popover>
                </template> -->

                <template v-if="curInfo.tagSource">

                  <el-popover placement="top" trigger="hover">

                    <div
                      style="
                        display: inline-block;
                        font-size: 14px;
                        width: 350px;
                      "
                      class="line-overflow"
                      slot="reference"
                    >

                      {{
                        `书籍：《${curInfo.tagSource.bookName}》  截止章节：${
                          curInfo.tagSource.endIdx
                        }
                    备注：${
                          curInfo.tagSource.remark
                            ? curInfo.tagSource.remark
                            : "暂无"
                        }`
                      }}

                    </div>

                    <div>

                      {{
                        `书籍：《${curInfo.tagSource.bookName}》  截止章节：${
                          curInfo.tagSource.endIdx
                        }
                    备注：${
                          curInfo.tagSource.remark
                            ? curInfo.tagSource.remark
                            : "暂无"
                        }`
                      }}

                    </div>

                  </el-popover>

                </template>
              </div>
            </el-form-item>
            <el-form-item label="标题" prop="title">
              <div
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                "
              >
                <el-input
                  type="textarea"
                  style="width: 15vw"
                  :autosize="{ minRows: 2, maxRows: 6 }"
                  :disabled="!canEdit"
                  class="margin-right-ten"
                  v-model="curInfo.title"
                ></el-input>
                <el-button
                  v-if="canEdit"
                  type="primary"
                  class="button-small"
                  @click="insertTitle"
                  >插入标题</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="正文展示封面图">
              <div
                style="
                  height: 32px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <el-switch
                  v-model="curInfo.showCoverPic"
                  :disabled="!canEdit"
                ></el-switch>
                <i
                  class="el-icon-question"
                  style="
                    font-size: 16px;
                    cursor: help;
                    color: #ccc;
                    margin-left: 10px;
                  "
                  title="开启后将把图文封面图展示到图文素材正文中"
                ></i>
              </div>
            </el-form-item>
            <el-form-item label="封面图" prop="coverPic">
              <img
                class="cover-pic"
                :src="curInfo.coverPic"
                alt=""
                v-if="curInfo.coverPic"
              />
              <div
                class="mask-hover"
                v-if="curInfo.coverPic && canEdit"
                @click="uploadImage($event, 'coverPic')"
              >
                <i class="iconfont icon-tupian" style="color: #fff"></i>
              </div>
              <div
                v-else-if="canEdit && !curInfo.coverPic"
                class="upload-image"
                @click="uploadImage($event, 'coverPic')"
              >
                <i class="icon-xinzeng iconfont"> </i>
              </div>
              <input
                type="file"
                ref="coverPic"
                style="display: none"
                @change="handleFileChange($event, 'coverPic')"
              />
            </el-form-item>
            <el-form-item label="顶图" prop="topPic">
              <img :src="curInfo.topPic" alt="" v-if="curInfo.topPic" />
              <div
                class="mask-hover"
                v-if="curInfo.topPic && canEdit"
                @click="uploadImage($event, 'topPic')"
              >
                <i class="iconfont icon-tupian" style="color: #fff"></i>
              </div>
              <div
                v-else-if="canEdit && !curInfo.topPic"
                class="upload-image"
                @click="uploadImage($event, 'topPic')"
              >
                <i class="icon-xinzeng iconfont"> </i>
              </div>
              <input
                type="file"
                ref="topPic"
                style="display: none"
                @change="handleFileChange($event, 'topPic')"
              />
            </el-form-item>
            <el-form-item label="底部图" prop="bottomPic">
              <div
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                "
              >
                <img :src="curInfo.bottomPic" alt="" v-if="curInfo.bottomPic" />
                <div
                  class="mask-hover"
                  v-if="canEdit && curInfo.bottomPic"
                  @click="uploadImage($event, 'bottomPic')"
                >
                  <i class="iconfont icon-tupian" style="color: #fff"></i>
                </div>
                <div
                  v-else-if="canEdit && !curInfo.bottomPic"
                  class="upload-image"
                  @click="uploadImage($event, 'bottomPic')"
                >
                  <i class="icon-xinzeng iconfont"> </i>
                </div>
                <input
                  type="file"
                  ref="bottomPic"
                  style="display: none"
                  @change="handleFileChange($event, 'bottomPic')"
                />
                <i
                  class="el-icon-question"
                  style="
                    font-size: 16px;
                    cursor: help;
                    color: #ccc;
                    margin-left: 10px;
                  "
                  title="请保持底部图片的大小为750 * 260像素"
                ></i>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-list padding-20">
          <el-button @click="handleSubmit('ruleForm', 'save')">保存</el-button>
          <el-button
            type="primary"
            @click="handleSubmit('ruleForm', 'put')"
            :loading="putLoading"
            >保存并上传</el-button
          >
        </div>
      </div>
    </div>
    <select-title
      :show="showTitleDialog"
      :classifyList="classifyList"
      :insertInfoType="insertInfoType"
      @close="showTitleDialog = false"
      @success="handleInsertBookTitle"
    />
    <select-resource
      :platformId="platformId"
      :show="showResourceDialog"
      @close="showResourceDialog = false"
      @success="handleResourceChange"
    >
    </select-resource>
    <select-image
      :show="showPicDialog"
      :classifyList="classifyList"
      @close="showPicDialog = false"
      @success="handleImageSuccess"
    />
  </el-drawer>
</template>

<script>
import WxPreview from './wx-preview.vue'
import SelectTitle from '@/views/service/components/selectTitle.vue'
import SelectResource from './select-resource.vue'
import SelectImage from '@/views/service/components/selectPic'
import { getClassifyList } from '@/api/classify'
import {
  perTagNews,
  getPlatformTagSource,
  editTagNews,
  deployTagNews
} from '@/api/service.js'
import { uploadFile } from '@/api/common.js'
export default {
  props: [
    'show',
    'tagChannelNewsId',
    'channelInfo',
    'canEdit',
    'isClickPartIndex'
  ],
  inheritAttrs: false,
  data () {
    return {
      itemList: [],
      curInfo: null,
      showCoverPic: false,
      itemIndex: 0,
      showTitleDialog: false,
      showResourceDialog: false,
      insertInfoType: 1, // 判断是在文本还是在图片的消息类型中插入标题(1是文本，2是图片)
      classifyList: [],
      platformId: null,
      channelContainer: [],
      resourceList: [],
      loading: false,
      showPicDialog: false,
      picType: null,
      putLoading: false,
      rules: {
        tagSource: [
          {
            required: true,
            message: '请选择素材!'
          }
        ],
        bottomPic: [
          {
            required: true,
            message: '请上传底部图片!'
          }
        ],
        coverPic: [
          {
            required: true,
            message: '请上传封面图!'
          }
        ]
      }
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.initData()
          this.initEvent()
        } else {
          this.$refs.wxPreview && this.$refs.wxPreview.resetData()
          this.unBindingEvent()
          this.curInfo = null
        }
      }
    },
    curInfo: {
      handler (newV) {
        if (newV) {
          this.$refs.wxPreview &&
            this.$refs.wxPreview.setItemList(newV, this.itemIndex)
          this.channelContainer[this.itemIndex] = newV
        }
      },
      deep: true
    }
  },

  methods: {
    initData () {
      this.loading = true
      this.platformId = this.channelInfo.accountChannel.platform

      getClassifyList({ type: 1, status: 1 }).then((res) => {
        this.classifyList = res
      })
      perTagNews({
        tagChannelNewsId: this.tagChannelNewsId
      })
        .then((res) => {
          this.itemList = res
          this.channelContainer = JSON.parse(JSON.stringify(this.itemList))
          this.curInfo = this.itemList[0]
          // getPlatformTagSource({
          //   bookName: res.length > 0 ? res[0].tagSource.bookName : "",
          //   pageSize: 10,
          // }).then((resource) => {
          //   this.resourceList = resource.list;
          //   res.forEach((item) => {
          //     item.resource = this.resourceList.find(
          //       (target) => target.id === item.tagSource.relation.tagSourceId
          //     );
          //     item.showCoverPic = item.showCoverPic ? true : false;
          //     // 默认全为false
          //   });
          //   this.itemList = res;
          //   this.channelContainer = JSON.parse(JSON.stringify(this.itemList));
          //   // this.curInfo =  this.isClickPartIndex ? this.itemList[this.isClickPartIndex] : this.itemList[0]
          //   this.curInfo = this.itemList[0];
          // });
        })
        .finally(() => {
          this.loading = false
        })
    },
    initEvent () {
      window.onkeyup = (e) => {
        const { keyCode } = e
        // 回车键
        if (
          keyCode === 13 &&
          document.querySelector('.service-components-selectTitle')
        ) {
          document
            .querySelector(
              '.service-components-selectTitle .dialog-footer button:nth-of-type(2)'
            )
            .click()
        }
      }
    },
    unBindingEvent () {
      window.onkeyup = null
    },
    getStyle () {
      return {
        marginLeft: this.canEdit ? '60px' : '0'
      }
    },
    insertTitle () {
      this.showTitleDialog = true
    },
    handleInsertBookTitle (title) {
      this.curInfo.title = title
    },
    chooseResource () {
      this.showResourceDialog = true
    },
    uploadImage (e, type) {
      // this.$refs[`${type}`].click();
      this.picType = type
      this.showPicDialog = true
    },
    handleImageSuccess (url) {
      const newImage = new Image()
      newImage.src = url
      newImage.onload = () => {
        if (this.picType === 'bottomPic') {
          if (newImage.width !== 750 && newImage.height !== 260) {
            this.$message({
              showClose: true,
              message:
                '您上传的底部图片尺寸不符合要求（750 * 260），请重新上传！',
              type: 'error'
            })
            return
          }
        }
        this.$set(this.curInfo, this.picType, url)
      }
    },
    handleFileChange (e, type) {
      const targetFile = e.target.files[0]
      uploadFile({ file: targetFile })
        .then((url) => {
          if (type === 'coverPic') {
            this.$set(this.curInfo, 'coverPic', url)
          }
          if (type === 'bottomPic') {
            this.$set(this.curInfo, 'bottomPic', url)
          }
          if (type === 'topPic') {
            this.$set(this.curInfo, 'topPic', url)
          }
        })
        .finally(() => {
          this.$refs.topPic.value = ''
          this.$refs.bottomPic.value = ''
          this.$refs.coverPic.value = ''
        })
    },
    handleResourceChange (info) {
      // this.$set(this.curInfo, "resource", info);
      this.$set(this.curInfo, 'tagSource', info)
    },
    chooseItem ({ newV, copyItemList }) {
      if (!copyItemList) return
      this.curInfo = copyItemList[newV]
      this.itemIndex = newV
      this.channelContainer = JSON.parse(JSON.stringify(copyItemList))
    },
    handleSubmit (refName, type) {
      this.$refs[refName].validate((valid) => {
        if (valid) {
          // 首先判断每个图文必传项是否有值

          for (let i = 0; i < this.channelContainer.length; i++) {
            const val = this.channelContainer[i]
            if (!val.coverPic || !val.bottomPic || !val.tagSource) {
              this.$message({
                showClose: true,
                message: `请检查第${i + 1}个图文配置是否有误`,
                type: 'error'
              })
              return
            }
          }

          const arr = []
          this.channelContainer.forEach((item) => {
            arr.push({
              tagSourceId: item.tagSource.id,
              title: item.title,
              showCoverPic: item.showCoverPic ? 1 : 0,
              topPic: item.topPic,
              coverPic: item.coverPic,
              bottomPic: item.bottomPic,
              qrcode_size: item.qrcode_size || 250,
              qrcode_x: item.qrcode_x || 493,
              qrcode_y: item.qrcode_y || 6
            })
          })

          this.$confirm(
            `您当前创建了${this.channelContainer.length}个图文素材，是否${
              type === 'save' ? '保存' : '保存并上传'
            }？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'info'
            }
          )
            .then(() => {
              editTagNews(this.tagChannelNewsId, {
                accountChannelId: this.channelInfo.accountChannelId,
                tagNewsList: arr
              }).then((res) => {
                const tagChannelNewsIds = res
                if (type === 'put') {
                  this.putLoading = true
                }
                if (type === 'save') {
                  this.$message({
                    showClose: true,
                    message: '修改成功!',
                    type: 'success'
                  })
                  this.handleClose()
                  this.$emit('refresh')
                }
                if (type === 'put') {
                  deployTagNews({
                    tagChannelNewsIds: `${tagChannelNewsIds}`
                  })
                    .then(() => {
                      this.$message({
                        showClose: true,
                        message: '操作成功!',
                        type: 'success'
                      })
                      this.handleClose()
                      this.$emit('refresh')
                    })
                    .finally(() => {
                      this.putLoading = false
                    })
                }
              })
            })
            .catch(() => {})
        }
      })
    },
    handleClose () {
      this.curInfo = null
      this.itemIndex = 0
      this.$emit('update:show', false)
    }
  },
  components: {
    WxPreview,
    SelectTitle,
    SelectResource,
    SelectImage
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-drawer__body {
  background-color: #f5f5f5 !important;
}
/deep/ .el-form {
  margin-top: 20px;
  .el-form-item__label {
    font-size: 14px !important;
  }
  .el-form-item__content {
    font-size: 14px !important;
    position: relative;
    max-height: 155px !important;
    .mask-hover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 306px;
      background: rgba($color: #000000, $alpha: 0.5);
      display: none;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        font-size: 20px;
      }
    }
    .upload-image {
      width: 120px;
      height: 120px;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      background: #fbfdff;
      cursor: pointer;
      &:hover {
        border: 1px dashed #409eff;
      }
    }
    &:hover {
      .mask-hover {
        display: block;
      }
    }
  }
}
/deep/ #el-drawer__title {
  display: none;
}
.wx-preview {
  position: sticky;
  top: 0;
}

.media-detail {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  .right {
    font-size: 16px;
    flex-grow: 1;
    // margin-left: 60px;
    .main-container {
      background: #fff;
      box-shadow: 0 0 8px 0 rgb(229, 229, 229);
      padding-bottom: 2px;
    }
    img {
      width: 306px;
      height: 155px;
    }
  }
  .button-list {
    margin-top: 16px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgb(229, 229, 229);
    text-align: center;
    border-top: 1px solid #eeeeee;
  }
}
</style>
