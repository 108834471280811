<template>
  <div class="wx-preview">
    <div class="wx-container">
      <div class="wx-info-title">
        <span v-if="channelInfo">{{
          channelInfo.accountChannel.channelName
        }}</span>
        <span v-else>{{
          accountChannelInfo && accountChannelInfo.channelName
        }}</span>
      </div>
      <div class="item-list" v-loading="loading">
        <template v-if="Array.isArray(copyItemList)">
          <div
            class="wx-item "
            v-for="(item, index) in copyItemList"
            :key="index"
            :class="{ 'is-active': index === curIndex }"
            @click="chooseActiveItem($event, index)"
          >
            <div v-if="index === 0" class="bg-item" ref="bgItem">
              <div class="wx-item-title">
                <v-clamp autoresize :max-lines="2">{{ item.title }}</v-clamp>
              </div>
            </div>
            <div v-else class="normal-item">
              <v-clamp autoresize :max-lines="2">{{ item.title }}</v-clamp>
              <div class="small-img" :ref="`smallImg`"></div>
            </div>
            <div
              class="item-operation"
              v-if="canEdit && curIndex === index && copyItemList.length > 1"
            >
              <div class="item-order">
                <template v-if="index === 0">
                  <div
                    v-if="copyItemList.length > 1"
                    @click.stop="changeOrder($event, 'down', index)"
                  >
                    <i class=" iconfont icon-xia"></i>
                  </div>
                </template>
                <template v-else-if="index === copyItemList.length - 1">
                  <div>
                    <i
                      class="iconfont icon-shang"
                      @click.stop="changeOrder($event, 'up', index)"
                    ></i>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <i
                      class="iconfont icon-shang"
                      @click.stop="changeOrder($event, 'up', index)"
                    ></i>
                  </div>
                  <div>
                    <i
                      class=" iconfont icon-xia"
                      @click.stop="changeOrder($event, 'down', index)"
                    ></i>
                  </div>
                </template>
              </div>
              <div class="item-delete">
                <i
                  class="iconfont icon-lajitong"
                  @click.stop="deleteItem(index)"
                ></i>
              </div>
            </div>
          </div>
        </template>
        <div
          class="add-item"
          @click="addPart"
          v-if="
            canEdit && Array.isArray(copyItemList) && copyItemList.length < 8
          "
        >
          <i class="iconfont icon-xinzeng margin-right-ten"></i>
          新增图文
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  props: [
    "canEdit",
    "channelInfo",
    "itemList",
    "curInfo",
    "accountChannelInfo",
    "isCanCheckDetail"
  ],
  data() {
    return {
      copyItemList: [],
      curIndex: 0,
      status: null,
      loading: false
    };
  },
  watch: {
    itemList: {
      handler(newV, oldV) {
        if (newV) {
          this.renderPreview();
        }
      },
      immediate: true
    },
    curIndex: {
      handler(newV, oldV) {
        if (newV !== null) {
          this.$emit("chooseItem", { newV, copyItemList: this.copyItemList });
        }
      }
      // immediate:true
    },
    // 针对详情页面不显示高亮边框
    canEdit: {
      handler(newV) {
        if (!newV) {
          this.curIndex = null;
        }
      },
      immediate: true
    }
  },
  methods: {
    renderPreview() {
      this.copyItemList = JSON.parse(JSON.stringify(this.itemList));
      this.renderItem();
    },
    renderItem() {
      if (this.copyItemList.length === 0) {
        return;
      }
      this.$nextTick(() => {
        this.$refs.bgItem[0].style = `background-image: ${
          this.copyItemList[0].coverPic
            ? `url('${this.copyItemList[0].coverPic}');background-size:cover;background-repeat:no-repeat`
            : "-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),color-stop(86%,rgba(0,0,0,0.15)))"
        } `;
        this.copyItemList.slice(1).forEach((item, index) => {
          this.$refs.smallImg[
            index
          ].style = `background-image: url('${item.coverPic}')`;
        });
        this.changeCurIndex();
      });
    },
    changeCurIndex() {
      if (!this.status) return;
      this.$nextTick(() => {
        this.isFlag = true;
        this.curIndex =
          this.status === "down" ? this.curIndex + 1 : this.curIndex - 1;
        this.status = null;
      });
    },
    chooseActiveItem($event, index) {
      if (!this.canEdit) {
        if (this.isCanCheckDetail) {
          // this.curIndex = index;

          this.$emit("handleCheckDetail", index);

          return;
        }
      }
      this.curIndex = index;
    },
    setItemList(item, index) {
      if (!this.copyItemList) return;
      this.$set(this.copyItemList, index, item);
      this.renderItem();
    },
    changeOrder($event, status, index) {
      let data = JSON.parse(JSON.stringify(this.copyItemList));
      if (status === "down") {
        let a = data[index];
        let b = data[index + 1];
        data[index] = b;
        data[index + 1] = a;
      } else {
        let a = data[index - 1];
        let b = data[index];
        data[index - 1] = b;
        data[index] = a;
      }
      this.copyItemList = JSON.parse(JSON.stringify(data));
      this.status = status;
      this.renderItem();
    },
    addPart() {
      this.copyItemList.push({
        title: "标题"
      });
      this.curIndex = this.copyItemList.length - 1;
    },
    deleteItem(index) {
      let flag = false;
      this.$confirm("是否删除该图文模块?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.copyItemList.splice(index, 1);
          if (index - 1 <= 0) {
            flag = true;
          }
          this.curIndex = index-- <= 0 ? 0 : index--;
          this.renderItem();
          if (flag) {
            this.$emit("chooseItem", {
              newV: this.curIndex,
              copyItemList: this.copyItemList
            });
          }
        })
        .catch(() => {});
    },

    resetData() {
      this.curIndex = 0;
      this.status = null;
      this.copyItemList = null;
    },
    checkStasus() {}
  },
  components: {
    VClamp
  }
};
</script>
<style lang="scss" scoped>
.is-active {
  border: 2px solid rgb(7, 193, 96);
}
.wx-preview {
  width: 310px;
  // width:20vw;
  background: #fff;
  border-radius: 4px;
  .wx-container {
    box-shadow: 0 0 8px 0 rgb(229, 229, 229);
    .wx-info-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 0;
      img {
        height: 24px;
        width: 24px;
        border-radius: 4px;
        object-fit: cover;
      }
      span {
        margin-left: 10px;
        font-size: 14px;
      }
    }
    .item-list {
      .wx-item {
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        .bg-item {
          position: relative;
          height: 155px;
          background-repeat: no-repeat;
          .wx-item-title {
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #fff;
            font-weight: 400;
            z-index: 1;
            font-size: 14px;
            line-height: 1.6;
          }
        }
        .normal-item {
          padding: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          > div:nth-of-type(1) {
            width: 75%;
            line-height: 1.6;
          }
          .small-img {
            height: 48px;
            width: 48px !important;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-color: rgb(225, 225, 225);
            // background-size
          }
        }
        .item-operation {
          position: absolute;
          left: 103%;
          top: 0%;
          padding: 20px 10px;
          background: #fff;
          border-radius: 25px;
          box-shadow: 0 0 8px 0 rgb(229, 229, 229);

          .item-order {
            font-size: 20px;
            > div {
              margin-bottom: 10px;
            }
          }
          .item-delete {
            font-size: 20px;
          }
        }
      }
      .empty-item {
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(0, 0, 0, 0)),
          color-stop(86%, rgba(0, 0, 0, 0.15))
        );
        border: 2px solid rgb(7, 193, 96);
      }
      .add-item {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .wx-preview {
    width: 200px;
    .bg-item {
      height: 100px !important;
    }
    .wx-container {
      .item-list {
        .wx-item {
          .normal-item {
            .small-img {
              height: 30px;
              width: 30px !important;
            }
          }
        }
      }
    }
  }
}
</style>
