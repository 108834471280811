<template>
  <div
    class="
      media-container
      border-radius-6
      box-shadow-light-grey
      padding-20
      height-calc-type1
    "
  >
    <div class="operation">
      <el-button @click="initData">刷新数据</el-button>
      <el-button type="primary" @click="addMedia">新增图文</el-button>
      <el-button
        type="warning"
        plain
        @click="bulkUpload"
        :disabled="selectList.length === 0"
        >批量上传</el-button
      >
      <el-button
        type="danger"
        plain
        @click="bulkDelete"
        class="margin-right-ten"
        :disabled="selectList.length === 0"
        >批量删除</el-button
      >
      <el-select
        v-model="originPlatform"
        clearable
        filterable
        placeholder="平台"
        class="margin-right-ten"
        style="width: 120px"
        @change="initData"
      >
        <el-option label="掌中云" :value="0"></el-option>
        <el-option label="阅文" :value="1"></el-option>
        <el-option label="阳光书城" :value="3"></el-option>
        <el-option label="悠书阁" :value="4"></el-option>
        <el-option label="网易文鼎" :value="5"></el-option>
        <el-option label="七悦" :value="6"></el-option>
        <el-option label="花生书城" :value="7"></el-option>
        <el-option label="海读" :value="8"></el-option>
        <el-option label="国风" :value="12"></el-option>
      </el-select>
      <el-select
        class="margin-right-ten"
        v-model="currentOfficial"
        placeholder="请选择公众号"
        style="width: 140px"
        @change="initData"
        filterable
        clearable
      >
        <el-option
          v-for="item in filterList()"
          :key="item.id"
          :label="item.channelName"
          :value="item.id"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select>
      <el-select
        class="margin-right-ten"
        style="width: 100px"
        v-model="status"
        placeholder="状态"
        @change="initData"
        filterable
        clearable
      >
        <el-option label="未上传" :value="0"></el-option>
        <el-option label="已上传" :value="1"></el-option>
        <el-option label="上传失败" :value="2"></el-option>
        <el-option label="上传中" :value="3"></el-option>
      </el-select>
      <SelectDeptUser
        :params="params"
        class="margin-right-ten"
      ></SelectDeptUser>
      <el-input
        class="margin-right-ten"
        style="width: 140px"
        clearable
        v-model="originNovel"
        placeholder="输入书籍"
      ></el-input>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
      <el-button class="margin-right-ten" @click="handleReset">重置</el-button>
    </div>
    <div class="custom-table">
      <el-table
        :data="dataList"
        v-loading="loading"
        style="width: 100%"
        height="calc(100vh - 310px)"
        @select-all="handleSelect"
        @select="handleSelect"
      >
        <el-table-column
          type="selection"
          width="55"
          :selectable="handleSelectEnable"
        >
        </el-table-column>
        <el-table-column label="Id" prop="id" min-width="80"></el-table-column>
        <el-table-column
          prop="accountChannel.platformStr"
          label="平台"
        ></el-table-column>
        <el-table-column
          label="公众号"
          prop="accountChannel.channelName"
          min-width="120"
        ></el-table-column>
        <el-table-column label="上传状态">
          <template slot-scope="scope">
            <el-tag :type="getType(scope.row.isDeploy).type">{{
              getType(scope.row.isDeploy).value
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="上传结果" min-width="100">
          <template slot-scope="scope">
            <template v-if="'deployMsg' in scope.row">
              <el-popover placement="right" trigger="hover">
                <span>{{ scope.row.deployMsg }}</span>
                <div class="line-overflow" slot="reference">
                  {{ scope.row.deployMsg }}
                </div>
              </el-popover>
            </template>
            <template v-else>
              <span>--</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="用户"
          prop="adminUser.name"
          min-width="100"
        ></el-table-column>
        <el-table-column label="创建时间" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.createdTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="updatedTime" min-width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.updatedTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="250">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top-start"
              slot="reference"
            >
              <el-button
                type="info"
                icon="el-icon-document"
                circle
                @click="checkDetail(scope.row, 1)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top-start"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="checkDetail(scope.row, 2)"
              ></el-button>
            </el-tooltip>

            <template v-if="scope.row.isDeploy !== 3">
              <el-tooltip
                class="item"
                effect="dark"
                content="上传"
                placement="top-start"
              >
                <el-button
                  circle
                  type="warning"
                  icon="el-icon-upload"
                  @click="handleUpload(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>

            <el-tooltip
              content="复制"
              class="item’"
              placement="top-start"
              effect="dark"
            >
              <el-button
                circle
                style="background-color: #66b1ff; color: #fff"
                icon="el-icon-document-copy"
                @click="handleRowClick('copy', scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              content="删除"
              placement="top-start"
              class="item"
              effect="dark"
            >
              <el-button
                circle
                type="danger"
                icon="el-icon-delete-solid"
                @click="handleRowClick('delete', scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- <el-dropdown
              size="medium"
              style="vertical-align: middle; margin-left: 10px"
              trigger="click"
              @command="command => handleRowClick(command, scope.row)"
            >
              <el-button class="button-small">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="copy">复制消息</el-dropdown-item>
                <el-dropdown-item style="color: #f56c6c" command="delete"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="复制图文"
      width="750px"
      center
      append-to-body
      :visible.sync="isCopy"
    >
      <div class="service-copy-message">
        <el-form label-width="100px">
          <el-form-item label="公众号">
            <el-select
              class="margin-right-ten"
              v-model="accountChannelIds"
              style="width: 80%"
              placeholder="请选择"
              multiple
              clearable
              filterable
            >
              <el-option
                v-for="item in filterChannelList"
                :key="item.id"
                :value="item.id"
                :label="item.channelName"
              >
                <span style="float: left">{{ item.channelName }}</span>
                <span style="float: right; margin-left: 15px; font-size: 13px"
                  >{{ item.platformStr }} - {{ item.uname }}</span
                >
              </el-option>
            </el-select>
            <el-button
              type="primary"
              @click="
                () => {
                  this.accountChannelIds = null;
                }
              "
              >清空</el-button
            >
          </el-form-item>
        </el-form>
        <div class="dialog-footer" style="text-align: center">
          <el-button @click="handleCopyClose">取 消</el-button>
          <el-button
            @click="handleCopyOk"
            type="primary"
            :loading="submitLoading"
            >确 定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <media-detail
      :canEdit="canEdit"
      :tagChannelNewsId="tagChannelNewsId"
      :show.sync="showDialog"
      :channelInfo="channelInfo"
      :isClickPartIndex="isClickPartIndex"
      @refresh="initData"
    ></media-detail>
    <add-media
      :canEdit="true"
      :show.sync="showAddMediaDialog"
      @refresh="initData"
    >
    </add-media>
    <show-main-content
      :show.sync="isShowMainContent"
      :channelInfo="channelInfo"
      :tagChannelNewsId="tagChannelNewsId"
      :isClickPartIndex="isClickPartIndex"
      @handleClose="handleConentClose"
    ></show-main-content>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectDeptUser from "@/components/SelectDeptUser.vue";
import {
  getPlatformTagNews,
  perTagNews,
  delPlatformTagNews,
  deployTagNews,
  addTagNews,
  copyPlatformTagNews,
} from "@/api/service.js";
import { customFile } from "@/api/common.js";
import { messagePlatformList } from "@/assets/js/options";
import MediaDetail from "./media-detail.vue";
import AddMedia from "./add-media.vue";
import WxPreview from "./wx-preview.vue";
import ShowMainContent from "./show-main-content.vue";

export default {
  data() {
    return {
      page: 1,
      pageSize: 15,
      currentOfficial: null,
      params: {},
      selectList: [],
      dataList: [],
      status: null,
      loading: false,
      total: 0,
      showDialog: false,
      showAddMediaDialog: false,
      tagChannelNewsId: null,
      channelInfo: null,
      itemList: [],
      canEdit: false,
      isShowDetailPopover: false,
      isShowEditPopover: false,
      contentLoading: false,
      isCanCheckDetail: true, // 是否可以查看详情 与canEdit相区分
      isClickPartIndex: null, // 用户从详情页面点击的图文索引
      isShowMainContent: false,
      isCopy: false,
      submitLoading: false,
      accountChannelIds: null,
      filterChannelList: [],
      originPlatform: "",
      originNovel: null,
    };
  },
  watch: {
    isShowMainContent: {
      handler(newV) {
        if (!newV) {
          this.isClickPartIndex = null;
        }
      },
    },
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    ...mapGetters(["officialList", "userInfo"]),
  },
  mounted() {
    // this.params.adminUserId = this.userInfo.id
    this.$set(this.params, "adminUserId", this.userInfo.id);
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      getPlatformTagNews({
        page: this.page,
        pageSize: this.pageSize,
        accountChannelId: this.currentOfficial,
        status: this.status,
        // *如果是超管(adminUserId为1,则默认不传递)
        adminUserId:
          this.params.adminUserId === 1 ||
          (this.params.adminUserId === "") | undefined | null
            ? null
            : this.params.adminUserId,
        deptId: this.params.deptId,
        originPlatform: this.originPlatform,
        originNovel: this.originNovel,
      })
        .then((res) => {
          res.list.forEach((item, index) => {
            item.isShowDetailPopover = false;
            item.isShowEditPopover = false;
          });
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addMedia() {
      this.showAddMediaDialog = true;
    },
    handlePageChange(page) {
      this.page = page;
      this.initData();
    },
    getType(status) {
      const obj = {
        0: {
          type: "info",
          value: "未上传",
        },
        1: {
          type: "success",
          value: "已上传",
        },
        2: {
          type: "danger",
          value: "上传失败",
        },
        3: {
          type: "warning",
          value: "上传中",
        },
      };
      return obj[`${status}`];
    },
    checkDetail(row, type) {
      this.contentLoading = true;
      this.channelInfo = row;
      this.tagChannelNewsId = row.id;
      this.canEdit = false;
      if (type === 2) {
        this.canEdit = true;
        this.showDialog = true;
        return;
      }
      if (type === 1) {
        this.isShowMainContent = true;
      }
      // perTagNews({
      //   tagChannelNewsId: this.tagChannelNewsId,
      // })
      //   .then((res) => {
      //     this.itemList = res;
      //     row.isShowDetailPopover = type === 1;
      //     row.isShowEditPopover = type === 2;
      //   })
      //   .finally(() => {
      //     this.contentLoading = false;
      //   });
    },
    handleDelete(row) {
      this.$confirm("是否删除该图文?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPlatformTagNews(row.id).then(() => {
            this.$message({
              showClose: true,
              message: "删除成功!",
              type: "success",
            });
            this.initData();
          });
        })
        .catch(() => {});
    },
    handleSearch() {
      this.initData();
    },
    handleSelect(val) {
      this.selectList = val.map((item) => item.id);
    },
    handleUpload(id) {
      this.$confirm("确认上传吗？", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        deployTagNews({
          tagChannelNewsIds: `${id}`,
        })
          .then(() => {
            this.$message({
              showClose: true,
              message: "操作成功!",
              type: "success",
            });
            this.initData();
          })
          .catch(() => {});
      });
    },
    bulkUpload() {
      this.$confirm("确认上传吗？", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deployTagNews({
            tagChannelNewsIds: this.selectList.join(","),
          }).then(() => {
            this.$message({
              showClose: true,
              message: "操作成功!",
              type: "success",
            });
            this.selectList = [];
            this.initData();
          });
        })
        .catch(() => {});
    },
    bulkDelete() {
      this.$confirm("确认删除所选内容吗?,删除之后不可恢复!", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        delPlatformTagNews(this.selectList.join(",")).then(() => {
          this.$message({
            showClose: true,
            message: "删除成功!",
            type: "success",
          });
          this.selectList = [];
          this.initData();
        });
      });
    },
    handleRowClick(type, data) {
      this.channelInfo = data;
      if (type === "delete") {
        this.handleDelete(data);
      } else if (type === "copy") {
        this.filterChannelList = this.channelList.filter(
          (item) => item.platform === this.channelInfo.accountChannel.platform
        );
        this.isCopy = true;
      }
    },
    filterList() {
      if (!this.originPlatform) return this.channelList;
      return this.channelList.filter(
        (item) => item.platform === this.originPlatform
      );
    },
    handleConentClose() {
      this.isShowMainContent = false;
    },
    handleCheckDetail(index) {
      this.isShowMainContent = true;
      this.isClickPartIndex = index;
    },
    handleCopyClose() {
      this.isCopy = false;
      this.accountChannelIds = null;
    },
    handleCopyOk() {
      if (
        !Array.isArray(this.accountChannelIds) &&
        this.accountChannelIds.length === 0
      ) {
        this.$message({
          showClose: true,
          message: "请选择公众号!",
          type: "error",
        });
        return;
      }
      this.submitLoading = true;
      copyPlatformTagNews({
        id: this.channelInfo.id,
        accountChannelIds: this.accountChannelIds.join(","),
      })
        .then(() => {
          this.$message({
            showClose: "true",
            message: "操作成功!",
            type: "success",
          });
          this.accountChannelIds = null;
          this.isCopy = false;
          this.initData();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    handleSelectEnable(row) {
      if (row.isDeploy === 3) {
        return false;
      } else {
        return true;
      }
    },
    handleReset() {
      this.currentOfficial = null;
      this.status = null;
      this.params = {};
      // this.initData();
    },
  },
  components: {
    SelectDeptUser,
    ShowMainContent,
    MediaDetail,
    WxPreview,
    AddMedia,
  },
};
</script>

<style lang="scss" scoped>
.wx-preview {
  margin: 0 auto;
}
.media-container {
  background: #fff;
  .custom-table {
    margin-top: 20px;
  }
}

.device-ios {
  width: 395px;
  height: 660px;
  margin: 0 auto;
  background: #111;
  border-radius: 55px;
  box-shadow: 0px 0px 0px 2px #aaa;
  padding: 60px 10px;
  position: relative;
  margin-right: 80px;
}

.device-ios:before {
  content: "";
  width: 60px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  background: #333;
  top: 30px;
}

.device-ios:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  margin-left: -30px;
  bottom: 0px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 5px solid #333;
}

.device-inner {
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  .device-container {
    height: 100%;
    overflow: auto;
  }
}
</style>
