<template>
  <el-dialog
    title="选择素材"
    width="1250px"
    top="5vh"
    append-to-body
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="operation">
      <el-select
        class="margin-right-ten"
        style="width: 140px"
        v-model="params.platformId"
        value-key="id"
        filterable
        placeholder="平台"
        :disabled='true'
        :clearable="true"
      >
        <el-option
          v-for="item in messagePlatformListDetail"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        >
        </el-option>
      </el-select>
      <el-input
        class="margin-right-ten"
        style="width: 140px"
        v-model="params.bookName"
        placeholder="输入书籍名称"
        @input='changeInput'
      ></el-input>
      <el-input
        class="margin-right-ten"
        style="width: 140px"
        v-model="params.remark"
        @input='changeInput'
        placeholder="输入备注"
      ></el-input>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
      <el-button type="info" @click="resetData(true)">重置</el-button>
    </div>
    <div class="custom-table">
      <el-table
        :data="dataList"
        max-height="580px"
        v-loading="loading"
        @current-change="row => (this.resource = row)"
      >
        <el-table-column prop="select" label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              type="radio"
              :value="resource ? resource.id : null"
              :label="scope.row.id"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="platformStr" label="平台"> </el-table-column>

        <el-table-column
          label="书籍名"
          prop="bookName"
          min-width="150"
        ></el-table-column>
        <el-table-column prop="endIdx" label="截止章节"> </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          min-width="200px"
        ></el-table-column>
        <el-table-column prop="relation.digest" width="150px" label="摘要">
          <template slot-scope="scope">
            <el-popover placement="top" title="摘要" trigger="hover">
              <div>
                {{ scope.row.relation.digest }}
              </div>
              <div class="line-overflow" slot="reference">
                {{ scope.row.relation.digest }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="needOpenComment" width="80px" label="可评论">
          <template slot-scope="scope">
            {{ scope.row.needOpenComment ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="onlyFansCanComment" label="仅粉丝评论" width="100">
          <template slot-scope="scope">
            {{ scope.row.onlyFansCanComment ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="上传时间" width="170">
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="button-list" style="text-align:center">
      <el-button type="primary" @click="handleOk"> 确定</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { messagePlatformListDetail, pickerOptions } from "@/assets/js/options";
import { getPlatformTagSource } from "@/api/service.js";
export default {
  props: ["show",'platformId'],
  data() {
    return {
      page: 1,
      pageSize: 15,
      resource: null,
      params: {},
      pickerOptions,
      messagePlatformListDetail,
      loading: false,
      total: 0,
      dataList: []
    };
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          if(this.platformId !== null) {
            this.$set(this.params,'platformId',this.platformId)
          }
          this.getResourceList();
        } else {
          this.resetData();
        }
      }
    }
  },
  methods: {
    getResourceList() {
      this.loading = true;
      getPlatformTagSource({
        page: this.page,
        pageSize: this.pageSize,
        ...this.params
      })
        .then(res => {
          this.total = res.total;
          this.dataList = res.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.getResourceList();
    },
    handleSearch() {
      this.page = 1;
      this.getResourceList();
    },
    handleClose() {
      this.$emit("close");
    },
    handleOk() {
      if (!this.resource) {
        this.$message({
          showClose: true,
          message: "请选择书籍!",
          type: "error"
        });
        return;
      }
      this.$emit("success", this.resource);
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    changeInput(){
      this.$forceUpdate()
    },
    resetData(flag = false) {
      // this.params = {};
      this.params.remark = null;
      this.params.bookName = null;

      this.page = 1;
      if (flag) {
        this.getResourceList();
      }
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
.operation {
}
</style>
