<template>
  <el-drawer
    title="正文内容"
    append-to-body
    size="55%"
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="main-container" id="container">
      <div class="left padding-20">
        <wx-preview
          ref="wxPreview"
          :canEdit="false"
          :channelInfo="channelInfo"
          :itemList="itemList"
          @chooseItem="chooseItem"
        ></wx-preview>
      </div>
      <div class="right padding-20">
        <template v-if="mainContent && channelInfo">
          <h2 class="rich_media_title" id="activity-name">
            {{ mainContent.title }}
          </h2>
          <div class="channel-name">
            <span class="author" v-if="'author' in mainContent.tagSource">{{
              mainContent.tagSource.author
            }}</span>
            <span class="channel-name">{{
              channelInfo.accountChannel.channelName
            }}</span>
            <span class="created-time">{{
              channelInfo.createdTime.slice(0, 11)
            }}</span>
          </div>
          <div class="divide-line"></div>
          <div class="top-pic">
            <img :src="mainContent.topPic" alt="" />
          </div>
          <div v-html="mainContent.tagSource.relation.content"></div>
        </template>
      </div>
    </div>
    <div id="empty-footer" class="empty-footer"></div>
    <back-up-down
      ref="backUpDown"
      targetUp="#container"
      targetDown="#empty-footer"
    ></back-up-down>
  </el-drawer>
</template>

<script>
import WxPreview from "./wx-preview.vue";
import backUpDown from "@/views/novel-put-management/components/back-up-down.vue";

import { perTagNews } from "@/api/service.js";
export default {
  props: ["show", "isClickPartIndex", "tagChannelNewsId", "channelInfo"],
  data() {
    return {
      mainContent: null,
      itemList: [],
      itemIndex: 0,
    };
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          this.renderContent();
        } else {
          this.$refs.wxPreview.resetData();

          this.mainContent = null;
          this.$refs.backUpDown.resetDirection();
        }
      },
    },
  },
  methods: {
    renderContent() {
      perTagNews({
        tagChannelNewsId: this.tagChannelNewsId,
      }).then((res) => {
        this.itemList = res;

        this.mainContent = this.itemList[this.itemIndex];
      });
    },
    handleClose() {
      this.resetData();
      this.$emit("handleClose");
    },
    chooseItem({ newV }) {
      this.mainContent = this.itemList[newV];
    },
    resetData() {},
  },
  components: {
    WxPreview,
    backUpDown,
  },
};
</script>
<style lang="scss" scoped>
/deep/ #el-drawer__title {
  display: none;
}
/deep/ .el-drawer__body {
  background-color: #f5f5f5 !important;
}
.wx-preview {
  position: sticky;
  top: 0;
}
.main-container {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
}
.right {
  flex-grow: 1;
  background: #fff;
  box-shadow: 0 0 8px 0 rgb(229, 229, 229);
  .rich_media_title {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
  .channel-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    > span {
      margin-right: 10px;
    }
    .channel-name {
      color: #7d90a9;
    }
    .created-time,
    .author {
      color: #ccc;
    }
  }
  .divide-line {
    height: 33px;
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 14px;
    border-bottom: 1px solid rgb(163, 163, 166) !important;
  }
}
/deep/ .right * {
  width: auto !important;
  max-width: 100% !important;
  height: auto !important;
}
</style>
